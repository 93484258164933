@primaryColor: #555;
@linkColor: #ab2727;
@linkHoverColor: lighten(@linkColor, 10%);

:root {
	--primary-color: @primaryColor;
	--primary-highlight-color: lighten(@primaryColor, 10%);
	--primary-disabled-color: lighten(@primaryColor, 20%);

	--link-color: @linkColor;
	--link-hover-color: @linkHoverColor;
}

#headerlogo {
	background-image: url(logo_full.svg);
	background-size: auto 45px;
	background-repeat: no-repeat;
	background-position: left center;
}
